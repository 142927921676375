import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { styles } from './styles.js';
import "animate.css/animate.min.css";
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { BannerSlider, ContainerBasic, ContainerBox, ContainerDark, ContainerText, Space, ContainerCompare, Line, GoHome } from './items.js';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


export const Main = (props) => {

    // IMAGES
    const image = require('./react/app/logo.png');

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [value, setValue] = useState('');
    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/logo.png');

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;

    useEffect(() => {

        // ---------------------------------------------------------------------------
        func(0, 0); // ID STRANKY -> prepnutie menu
        GoHome(); // Scroll na začiatok stránky po načítaní
        // ---------------------------------------------------------------------------

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language, props.company]);



    return (
        <div style={{ ...styles.BlockCenter, minHeight: 200, paddingTop: 20 }}>
        </div>
    )
}
