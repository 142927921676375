import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { styles } from './styles.js';
import "animate.css/animate.min.css";
import { Debug, GetLang, LoadTheme } from './functions.js';
import './globals.js';
import './themes.js';
import { BannerSlider, ContainerBasic, ContainerBox, ContainerDark, ContainerText, Space, ContainerCompare, Line, GoHome } from './items.js';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


export const Downloads = (props) => {

    // IMAGES
    const image = require('./react/app/logo.png');
    let params = useParams();

    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [value, setValue] = useState('');
    const test = Array.from({ length: 20 }, (_, index) => '@hour'.replace('@hour', String(index).padStart(3, '0')));

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/logo.png');

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;

    useEffect(() => {

        // ---------------------------------------------------------------------------
        func(0, 0); // ID STRANKY -> prepnutie menu
        GoHome(); // Scroll na začiatok stránky po načítaní
        // ---------------------------------------------------------------------------

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language, props.company]);

    const db_update = async (typ) => {

        try {
            const response = await fetch(
                global.db_url + 'downloads_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: params.email == undefined ? '' : params.email,
                    typ: typ
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
            }

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div style={{ ...styles.BlockCenter, minHeight: 200, paddingTop: 40, paddingBottom:40 }}>
            <p style={{ ...styles.TextLarge, color: global.theme_white, fontWeight:'600' }}>{lang.download_title}</p>
            <p style={{ ...styles.TextXSmall, color: global.theme_white }}>{lang.download_text}</p>
            <div style={{ ...styles.Block, marginTop: 20 }}>
                <a onClick={() => db_update(0)} href="itms-services://?action=download-manifest&url=https://vizzie.app/public/applications/manifest.plist" style={{ ...styles.ButtonDark, textDecoration: 'none' }} >
                    <div style={{ ...styles.ButtonThemed, backgroundColor: color.button_dark, width:180 }}>
                        <div style={{ ...styles.Block, width: 180, height: 34 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.download}</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}
