import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { sk } from './language_sk.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faShoppingCart, faSearch, faAngleDoubleLeft, faChevronLeft, faAngleDoubleRight, faChevronRight, faBars, faCross, faTimesCircle, faList, faAngleDown, faAnglesUp, faAngleLeft, faGear, faCaretUp, faArrowRight, faAngleRight, faPerson, faUser, faPenToSquare, faClose, faRightFromBracket, faEdit, faArrowRightToBracket, faHome, faPaperPlane, faPhone, faCheck, faXmark, faHeart, faPalette, faP, faImage, faPlay, faCheckCircle, faWarning, faPen, faTrash, faCalculator, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faMobile, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Drawer, Popper, Slide, Tooltip, getCardActionAreaUtilityClass } from '@mui/material';
//import Slider from "react-slick";
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, useMediaQuery } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
/*
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
*/
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Parallax } from 'react-scroll-parallax';
import { Spin as Hamburger } from 'hamburger-react';
import { Debug, GetLang, LoadLanguage, LoadTheme } from './functions.js';
import { GetTodayYear, Today } from './functions_date.js';
import { faPallet, height } from '@fortawesome/free-solid-svg-icons/faPallet';
import ReCAPTCHA from 'react-google-recaptcha';

export function IsEmpty(data) {
    // kontrola ci je retazec prázdny
    if (data.trim() == '') {
        return true;
    } else {
        return false;
    }
}

export const Logo = (props) => {

    const logo = require('./react/app/logo_vision.png');
    const logo_white = require('./react/app/logo_vision.png');

    const lang = props.lang;
    var color = global.themes[props.theme];

    return (
        <div style={{ ...styles.BlockRowRaw }}>
            <div style={{ ...styles.Block, width: undefined, height: props.height }}>
                <img src={props.white == true ? logo_white : logo} style={{ width: '100%', height: '100%', maxWidth: props.height - 16, maxHeight: props.height - 16, objectFit: 'contain', transitionDuration: '0.5s' }}></img>
            </div>
            <div style={{ ...styles.Block, width: undefined, height: props.height, marginLeft: 10 }}>
                <p style={{ ...styles.TextXLarge, color: color.white }}><b>{lang.title1.toUpperCase()}</b><span style={{ marginLeft: 12, letterSpacing: 2 }}>{lang.title2}</span></p>
            </div>
        </div>
    )
}

export function GetImageLink(path) {
    return path.substring(0, 4) == 'http' ? path : global.web + "/" + path;
}


/*
*
* =================================================================================================================
* TOP MENU
* 
* výber jazyka
* 
* =================================================================================================================
*
*/
export const MenuTop = (props) => {

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [value, setValue] = useState(0);

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const navigate = useNavigate();

    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');

    // CONSTANTS
    const menuHeight = global.menu_top_size;
    const iconSize = 30;

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    const disabled = 0.3;

    let { func } = props;

    useEffect(() => {

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language]);

    const ChangeLanguage = (id) => {
        func(0, id);
    }

    const ChangeTheme = (id) => {
        func(1, id);
    }


    return (
        <div style={{ ...styles.Block, height: menuHeight, backgroundColor: color.top_menu_color }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                <div style={{ ...styles.BlockRow, width: global.max_width_padding }}>
                    <div style={{ ...styles.BlockRowRaw, width: '50%' }}>
                    </div>
                    <div style={{ ...styles.BlockRight, width: '50%' }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {global.themes_enabled == true ?
                                <div onClick={() => ChangeTheme(theme == 0 ? 1 : 0)} style={{ ...styles.Block, width: iconSize, height: iconSize, marginRight: 40, background: color.button_palette, borderRadius: iconSize / 2 }}>
                                    <FontAwesomeIcon style={{ height: 22, color: color.white }} icon={faPalette} />
                                </div>
                                : null}
                            <div onClick={() => ChangeLanguage(1)} style={{ width: iconSize, height: iconSize, marginRight: 20 }}>
                                <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: iconSize, maxHeight: iconSize, objectFit: 'contain', opacity: language == 0 ? 1 : disabled }}></img>
                            </div>
                            <div onClick={() => ChangeLanguage(2)} style={{ width: iconSize, height: iconSize }}>
                                <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: iconSize, maxHeight: iconSize, objectFit: 'contain', opacity: language == 1 ? 1 : disabled }}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

/*
*
* =================================================================================================================
* MENU
* 
* hlavné menu
* 
* =================================================================================================================
*
*/
export const Menu = (props) => {


    const logo_marvi = require('./react/app/logo.png');
    const background = require('./react/app/background_menu.jpg');

    const [isStacked, setStacked] = useState(false);
    const [theme, setTheme] = useState(0);
    const [language, setLanguage] = useState(0);

    // VALUES
    const [index, setIndex] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuStarted, setMenuStarted] = useState(false);

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const navigate = useNavigate();

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    // BUTTONS
    var buttonSize = isSmall ? 80 : isMedium ? 110 : 128;
    var fontSize = isSmall ? 20 : isMedium ? 16 : 20;

    // SMALL MENU
    const menuSmallHeight = 60;
    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');
    const disabled = 0.3;

    const iconSize = 30;


    const stickyRef = useRef(null);
    let { func } = props;

    useEffect(() => {

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);
        setIndex(props.id);

        const handleScroll = () => {
            if (stickyRef.current) {
                const stickyTop = stickyRef.current.getBoundingClientRect().top;
                setStacked(stickyTop <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [props.theme, props.language, props.id]);


    const ChangeIndex = (id) => {
        CloseMenu();
        setIndex(id);
        func(1, id);
    }

    const ChangeLanguage = (id) => {
        CloseMenu();
        func(2, id);
    }

    const ChangeTheme = (id) => {
        CloseMenu();
        func(3, id);
    }

    const OpenMenu = () => {
        setMenuOpen(true);
        setTimeout(() => {
            setMenuStarted(true);
        }, 250);
    }


    const CloseMenu = () => {
        setMenuOpen(false);
        setMenuStarted(false);
    }


    const MenuButton = (props) => {

        var buttonHeight = global.menu_size_stacked;// isStacked ? global.menu_size_stacked : global.menu_size_expanded;
        const buttonHeightOffset = 8;
        const radius = 16;

        return (
            <div
                onClick={() => ChangeIndex(props.id)}
                style={{
                    ...styles.Block,
                    width: buttonSize,
                    height: buttonHeight,
                    cursor: 'pointer',
                }}>
                <div style={{ ...styles.Block, backgroundColor: index == props.id ? color.white : undefined, height: buttonHeight - buttonHeightOffset, borderRadius: radius }}>
                    <p style={{ ...styles.TextSmall, color: index == props.id ? color.black : color.white, fontSize: fontSize, letterSpacing: 1 }}>{props.name}</p>
                </div>
            </div>
        )
    }

    const MenuButtonSmall = (props) => {

        const buttonHeight = 56;
        const radius = 8;
        const offset = 12;

        return (
            <div
                onClick={() => ChangeIndex(props.id)}
                style={{
                    ...styles.Block,
                    width: '100%',
                    height: buttonHeight,
                    cursor: 'pointer',
                    background: 'linear-gradient(to right,#FFFFFF12,#FFFFFF00)',
                    borderRadius: radius,
                    marginBottom: 10,
                }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, width: buttonHeight + 10, height: buttonHeight }}>
                        <div style={{ ...styles.Block, width: buttonHeight - offset, height: buttonHeight - offset, background: props.background, borderRadius: radius }}>
                            <FontAwesomeIcon style={{ height: 24, color: color.white }} icon={props.icon} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: undefined, height: buttonHeight }}>
                        <p style={{ ...styles.TextSmall, color: color.white, fontSize: fontSize, letterSpacing: 1 }}>{props.name}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            ref={stickyRef}
            style={{
                ...styles.BlockCenter,
                height: isSmall ? global.menu_size_stacked : isStacked ? global.menu_size_stacked : global.menu_size_expanded,
                background: color.menu_color,
                position: 'sticky',
                zIndex: 100,
                top: 0,
                transition: 'height 0.50s ease',
            }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                <div style={{ ...styles.BlockRow, width: global.max_width_padding }}>
                    {/* **************************************************************************************************
                        LOGO
                        ************************************************************************************************** */}
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '80%' : '30%', height: isSmall ? global.menu_size_stacked : isStacked ? global.menu_size_stacked : global.menu_size_expanded }}>
                        <div onClick={() => ChangeIndex(0)} style={{ ...styles.Block, width: undefined, cursor: 'pointer' }}>
                            <Logo theme={props.theme} lang={lang} height={isSmall ? global.menu_size_stacked : isStacked ? global.menu_size_stacked : global.menu_size_expanded} />
                        </div>
                    </div>

                    {/* **************************************************************************************************
                        BUTTONS
                    ************************************************************************************************** */}
                    <div style={{ ...styles.BlockRight, width: isSmall ? '20%' : '70%', height: isSmall ? global.menu_size_stacked : isStacked ? global.menu_size_stacked : global.menu_size_expanded }}>
                        {isSmall ?
                            <div onClick={() => OpenMenu()} style={{ ...styles.Block, width: 40, cursor: 'pointer' }}>
                                <FontAwesomeIcon style={{ height: 20, color: color.white }} icon={faBars} />
                            </div>
                            :
                            <div style={{ ...styles.BlockRowRaw }}>
                                <MenuButton id={0} name={lang.home} />
                                {/*
                                <MenuButton id={1} name={lang.products} />
                                <MenuButton id={2} name={lang.gallery} />
                                */}
                                <MenuButton id={3} name={lang.contact} />
                            </div>
                        }
                    </div>
                </div>
            </div>

            {isSmall ?
                /*
                *
                * =================================================================================================================
                * MENU - SMALL DEVICES
                * =================================================================================================================
                *
                */
                <Dialog fullScreen open={menuOpen} style={{ zIndex: 999 }}>
                    <div style={{ ...styles.BlockCenter, height: window.innerHeight, background: `url(${background})`, backgroundSize: 'cover', overflowY: 'scroll' }}>
                        <div style={{ ...styles.Block, height: menuSmallHeight, backgroundColor: '#000000A0' }}>
                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', height: menuSmallHeight }}>
                                    <p style={{ ...styles.TextLarge, color: color.white }}>{lang.menu}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '70%' }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        {global.themes_enabled == true ?
                                            <div style={{ ...styles.BlockRight, width: 60, height: menuSmallHeight }}>
                                                <div onClick={() => ChangeTheme(theme == 0 ? 1 : 0)} style={{ ...styles.Block, width: iconSize, height: iconSize, background: color.button_palette, borderRadius: iconSize / 2 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.white }} icon={faPalette} />
                                                </div>
                                            </div>
                                            : null}
                                        <div style={{ ...styles.Block, width: 60, height: menuSmallHeight }}>
                                            <div onClick={() => ChangeLanguage(0)} style={{ width: iconSize, height: iconSize }}>
                                                <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: iconSize, maxHeight: iconSize, objectFit: 'contain', opacity: language == 0 ? 1 : disabled }}></img>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.Block, width: 60, height: menuSmallHeight }}>
                                            <div onClick={() => ChangeLanguage(1)} style={{ width: iconSize, height: iconSize }}>
                                                <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: iconSize, maxHeight: iconSize, objectFit: 'contain', opacity: language == 1 ? 1 : disabled }}></img>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 60, height: menuSmallHeight }}>
                                            <IconButton onClick={() => CloseMenu()} style={{ ...styles.ButtonClose }}>
                                                <FontAwesomeIcon style={{ width: 12, color: color.black }} icon={faXmark} />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 30 }}>
                            <img src={logo_marvi} style={{ width: '100%', height: '100%', maxWidth: 200, maxHeight: 200, objectFit: 'contain', transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transform: menuStarted == true ? 'scale(1.00)' : 'scale(1.30)' }}></img>
                        </div>

                        <div style={{ ...styles.BlockCenter }}>
                            <div style={{ ...styles.Block, width: '90%' }}>
                                <div style={{ ...styles.Block, transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transitionDelay: '0s' }}>
                                    <MenuButtonSmall id={0} name={lang.home} icon={faHome} background={'linear-gradient(135deg,#550500,#991000)'} />
                                </div>
                                {/*
                                <div style={{ ...styles.Block, transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transitionDelay: '0.05s' }}>
                                    <MenuButtonSmall id={1} name={lang.products} icon={faP} background={'linear-gradient(135deg,#550055,#990099)'} />
                                </div>
                                <div style={{ ...styles.Block, transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transitionDelay: '0.1s' }}>
                                    <MenuButtonSmall id={2} name={lang.gallery} icon={faImage} background={'linear-gradient(135deg,#002255,#006699)'} />
                                </div>
                                */}
                                <div style={{ ...styles.Block, transitionDuration: '1.00s', opacity: menuStarted == true ? 1 : 0, transitionDelay: '0.15s' }}>
                                    <MenuButtonSmall id={3} name={lang.contact} icon={faPhone} background={'linear-gradient(135deg,#005555,#009999)'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                : null}
        </div>
    )

};


/*
*
* =================================================================================================================
* FOOTER
* 
* kontaktné informácie
* 
* =================================================================================================================
*
*/
export const Footer = (props) => {

    const [theme, setTheme] = useState(props.theme);
    const [language, setLanguage] = useState(props.language);

    // VALUES
    const [year, setYear] = useState(GetTodayYear());

    // SCREEN SIZES
    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const navigate = useNavigate();

    const padding = 40

    // LANGUAGE
    var lang = GetLang(language);

    // THEME / COLORS
    var color = global.themes[theme];

    let { func } = props;
    const linkOffset = 15;

    useEffect(() => {

        lang = GetLang(props.language);
        color = global.themes[props.theme];

        setTheme(props.theme);
        setLanguage(props.language);

        return () => {
        };

    }, [props.theme, props.language, props.company]);


    const ButtonLink = (props) => {
        const [selected, setSelected] = useState(false);

        return (
            <p
                onClick={() => func(0, props.id)}
                style={{
                    ...styles.TextXSmall,
                    color: selected == true ? color.light_blue : color.white,
                    cursor: 'pointer',
                    marginTop: linkOffset
                }}
                onMouseOverCapture={() => setSelected(true)} onMouseLeave={() => setSelected(false)}
            >{props.name}</p>
        );
    }

    return (
        <div elevation={1} style={{
            ...styles.BlockCenter,
            backgroundColor: color.footer_background,
            color: color.white,

        }}>
            <div style={{ ...styles.Block, maxWidth: global.max_screen, paddingTop: padding, paddingBottom: padding }}>
                <div style={{ ...styles.Block, width: global.max_width_padding }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                        <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%' }}>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '70%' }}>
                                <Logo white theme={props.theme} lang={lang} height={100} />
                            </div>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <ButtonLink id={0} name={lang.home} />
                            <ButtonLink id={3} name={lang.contact} />
                        </div>
                    </div>

                    <div style={{ ...styles.Block, maxWidth: global.max_screen, paddingTop: padding / 2, borderTop: '1px solid #FFFFFF30', marginTop: 40 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%' }}>
                                <p style={{ ...styles.TextTiny, color: color.white }}>{'Datadream s.r.o.'} - {lang.copyright}: {year}</p>
                            </div>
                            <div style={{ ...isSmall ? styles.BlockLeft : styles.BlockRight, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 10 : 0 }}>
                                <p style={{ ...styles.TextTiny, color: color.white }}>{props.company != false ? props.company.email : ''}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

};


export const Loading = () => {
    /*
            USAGE:
            <Loading />
            */


    const logo = require('./react/app/logo_loading.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <div style={{ ...styles.Block, width: 80, height: 80, backgroundColor: global.theme_white, borderRadius: 50 }}>
                <img src={logo} style={{ width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_blue }} size={70} />
            </div>
        </Backdrop>
    );
}




export const GoHome = () => {
    var timer = setTimeout(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, 250);
}

export const Line = (props) => {
    return (
        <div style={{ width: '100%', height: 1, backgroundColor: props.color != undefined ? props.color : global.theme_medium, marginTop: props.marginTop == undefined ? 0 : props.marginTop, marginBottom: props.marginBottom == undefined ? 0 : props.marginBottom }} />
    )
}

export const GetSlovom = (value) => {
    // vstup 0.0, 123.50, ....
    // vráti slovom od  0 - 999 000
    // aj centy 
    var numbers_1 = ['nula', 'jeden', 'dva', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_2 = ['nula', 'jeden', 'dve', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_3 = ['desať', 'jedennásť', 'dvanásť', 'trinásť', 'štrnásť', 'pätnásť', 'šestnásť', 'sedemnásť', 'osemnásť', 'devätnásť'];
    var numbers_4 = ['', 'desať', 'dsať', 'dsať', 'dsať', 'desiat', 'desiat', 'desiat', 'desiat', 'desiat'];

    var str_ = value.toString().replaceAll(',', '.');
    var arr = str_.split('.');

    var result = '';
    var result2 = '';
    if (arr.length > 0) {
        var str = arr[0];
        var eur = parseInt(str);

        var n = 0;

        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);
            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    result = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result = numbers_1[val] + numbers_4[val] + result;
                    }
                }
            }
            if (n == 2) {
                // stovky 100 - 900
                if (val > 0) {
                    result = numbers_2[val] + 'sto' + result;
                }
            }
            // ------------------------------------------------------------
            // 1 000 - 100 000
            // ------------------------------------------------------------
            if (n == 3) {
                // tisíce 1 - 9
                if (val > 0) {
                    result2 = numbers_2[val];
                }
            }
            if (n == 4) {
                // desaťtisíce 10 000 - 90 000
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result2 = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result2 = numbers_1[val] + numbers_4[val] + result2;
                    }
                }
            }
            if (n == 5) {
                // stotisíce 100 000 - 900 000
                if (val > 0) {
                    result2 = numbers_2[val] + 'sto' + result2;
                }
            }
            n++;
        }
    }

    if (result2 != '') {
        result2 = result2 + 'tisíc';
    }

    /* **********************************************************************
        CENTY
    ********************************************************************** */
    var centy = '';
    if (arr.length > 1) {
        var str = arr[1];

        if (str.length == 1) {
            str = str + '0';
        }
        var cent = parseInt(str);

        var n = 0;
        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);

            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    centy = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    centy = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        centy = numbers_1[val] + numbers_4[val] + centy;
                    }
                }
            }
            n++;
        }
    }
    result = result2 + result;
    if (result == '') {
        result = numbers_1[0];
    }

    var eur_text = 'eur';
    if (eur == 0) {
        eur_text = 'eur';
    }
    if (eur == 1) {
        eur_text = 'euro';
    }
    if (eur > 1 && eur < 5) {
        eur_text = 'eura';
    }
    if (eur > 4) {
        eur_text = 'eur';
    }
    result = result + ' ' + eur_text;

    if (centy != '') {
        var cent_text = 'cent';
        if (cent == 1) {
            cent_text = 'cent';
        }
        if (cent > 1 && cent < 5) {
            cent_text = 'centy';
        }
        if (cent > 4) {
            cent_text = 'centov';
        }
        result = result + ' a ' + centy + ' ' + cent_text;
    }
    return result;
}


export const TextInput = (props) => {
    /* USAGE

            const [userData, setUserData] = useState({mobil: ''});

            <TextInput require calc numeric precision={3} money money_text={'€'} redraw theme={props.theme} enabled={true} lang={lang} value={userData.mobil} label={lang.mobil} func={(txt) => { userData.mobil = txt }} />

                required    -> text je vyzadovany - pod textfieldom oznam
                require     -> text je vyzadovany - CERVENY okraj na lavo
                not_zero    -> nesie byt nulovy
                date        -> vklad dátumu
                time        -> vklad casu
                outline     -> ak je chyba, zobrazí sa cervený ramik bez textu
                phone       -> jedna sa o tel. cislo v medzinárodnom formate
                email       -> kontroluje správnost e-mailovej adresy
                enabled     -> editovanie true / false
                label       -> place holder
                redraw      -> text bude prekreslovaný pri zmene iných textových polí - setRedraw(!redraw)
                type        -> default = 'text', 'date'
                placeholder -> placeholder text
                money       -> zobrazi menu na konci textboxu
                money_text  -> text na konci textboxu (undefined == money)
                width       -> širka boxu
                height      -> výška textfieldu
                center      -> vycentrovať titulok aj box
                numeric     -> zadávanie len číslic [0-9] '.' ','   -> FLOAT VALUE
                calc        -> textové pole aj aj ako kalkulacka
                precision   -> počet desatiných miest pri kalkulačke
                decimal     -> zadávanie len číslic [0-9]           -> INT VALUE
                rows        -> pocet riadkov
                search      -> search štýl
                percentage  -> vklad percent
                end         -> text na konci
                maxLength   -> Maximálny počet zadavaných znakov
                enter       -> vráti text az po stlacení tlacitka enter a ako druhý parameter 'enter'
                excape      -> zrusi zadavanie a vrati ako druhy parameter 'escape'

            
                const TextResult = (text, button) => {
                    button -> undefined, enter, escape    
                }
            */



    const [text, setText] = useState(props.value);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [calcResult, setCalcResult] = useState('');
    const [calcOpen, setCalcOpen] = useState(false);

    const timer = useRef();

    let { func } = props;
    var lang = props.lang;
    let color = global.themes[props.theme == undefined ? 0 : props.theme];
    const divRef = React.useRef();
    const precision = props.precision == undefined ? 2 : props.precision;

    useEffect(() => {

        setAnchorEl(divRef.current);

        Check(props.value);
        if (props.redraw != undefined) {
            setText(props.value);
        }

        return () => {
        };

    }, [props.value]);

    const ChangeText = (txt) => {
        if (props.numeric == true) {
            if (props.calc) {
                if (/^[0123456789,.+-/*()]+$/.test(txt)) {
                    setText(txt);
                    Check(txt);
                    if (props.enter == undefined) {
                        func(txt);
                    }
                }
            } else {
                if (/^[0123456789,.]+$/.test(txt)) {
                    setText(txt);
                    Check(txt);
                    var result = txt.replaceAll(',', '.');
                    if (props.enter == undefined) {
                        func(result);
                    }
                }
            }
            if (txt == '') {
                setText(txt);
                Check(txt);
                var result = txt.replaceAll(',', '.');
                if (props.enter == undefined) {
                    func(result);
                }
            }
        } else if (props.decimal == true) {
            if (/^[0123456789]+$/.test(txt)) {
                setText(txt);
                Check(txt);
                func(txt);
            }
            if (txt == '') {
                setText(txt);
                Check(txt);
                if (props.enter == undefined) {
                    func(txt);
                }
            }
        } else if (props.percentage == true) {
            if (/^[0123456789%,.+-]+$/.test(txt)) {
                setText(txt);
                //Check(txt);
                var result = txt.replaceAll(',', '.');
                if (props.enter == undefined) {
                    func(result);
                }
            } else {
                if (txt == '') {
                    setText('');
                }
            }

        } else if (props.search == true) {
            setText(txt);
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                func(txt);
            }, 500);

        } else {
            setText(txt);
            Check(txt);
            if (props.enter == undefined) {
                func(txt);
            }
        }
    }

    const Check = (text) => {
        if (error != '') {
            setError('');
        }
        if (text != undefined) {
            if (props.phone) {
                if (text.trim() != '') {
                    if (text[0] != '+') {
                        setError(lang.format_error);
                    }
                }
            }

            if (props.numeric) {
                if (props.not_zero) {
                    if (text.trim() != '') {
                        if (parseInt(text) == 0) {
                            setError(lang.non_zero_error);
                        }
                    }
                }
            }


            if (props.calc) {
                if (text.includes('+') || text.includes('-') || text.includes('*') || text.includes('/') || text.includes('(') || text.includes(')')) {
                    setCalcOpen(true);
                } else {
                    setCalcOpen(false);
                }
                var tmp = ParseCondition(text);;
            }
        }
    }

    function ParseCondition(txt_) {
        var txt = txt_.replaceAll(',', '.');
        if (txt == '') {
            txt = '0';
        }
        var result = txt;
        try {
            result = eval(txt);
            result = parseFloat(result).toFixed(precision);
            setCalcResult(result);
        } catch (e) {
            if (e instanceof SyntaxError) {
                result = txt;
                setCalcResult('');
            }
        }

        return result;
    }

    const LostFocus = () => {
        // LOST FOCUS
        if (props.calc) {
            setText(calcResult);
            func(calcResult);
        }
        if (props.percentage) {
            var result = text;
            if (text.includes('%')) {
                var value = text.replaceAll('%', '')
                value = value.replaceAll('+', '');
                value = value.replaceAll('-', '');
                value = parseFloat(value);
                value = value / 100;
                if (text[0] == '-') {
                    result = 1 - value;
                } else {
                    result = 1 + value;
                }
            }
            func(String(result));
        }
        setEditing(false);
    }

    const Clear = () => {
        ChangeText('');
    }

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block }}>
            {props.label != undefined && props.label != '' ?
                <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: color.dark_gray, marginLeft: props.radius == undefined ? 2 : props.radius / 2, textAlign: 'left' }}>{props.label}</p>
                : null}
            <TextField
                ref={divRef}
                inputRef={props.inputRef}
                value={text}
                onBlur={() => LostFocus()}
                onFocus={() => setEditing(true)}
                onInput={e => ChangeText(e.target.value)}
                size="small"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                style={{ width: props.width == undefined ? '100%' : props.width, maxWidth: props.search ? props.width == undefined ? 300 : props.width : undefined }}
                placeholder={props.placeholder == undefined ? '' : props.placeholder}
                multiline={props.rows != undefined ? true : false}
                rows={props.rows != undefined ? props.rows : 1}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        if (props.calc) {
                            setText(calcResult);
                            setCalcOpen(false);
                            func(calcResult);
                        }
                        if (props.enter == true) {
                            func(text, 'enter');
                            //setText('');
                        }
                    }
                    if (event.key === 'Escape') {
                        if (props.escape == true) {
                            func(text, 'escape');
                        }
                    }

                }}
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                    },
                    fieldset: { borderWidth: props.enabled == false ? 0 : 1, borderColor: error != '' ? color.red : color.xxgray, borderLeft: props.require == true ? '3px solid #FF0000' : undefined }
                }}
                inputProps={{ maxLength: props.maxLength == undefined ? undefined : props.maxLength }}
                InputProps={{
                    startAdornment:
                        props.money == true ?
                            <InputAdornment position="start">
                                <div style={{ ...styles.Block, borderRight: '1px solid #A0A0A0' }}>
                                    <p style={{ ...styles.TextSmall, marginRight: 10 }}>{props.money_text == undefined ? lang.money : props.money_text}</p>
                                </div>
                            </InputAdornment> :
                            props.search == true ?
                                <InputAdornment position="start">
                                    <div style={{ ...styles.Block }}>
                                        <FontAwesomeIcon style={{ height: 16, color: color.dark_gray }} icon={faSearch} />
                                    </div>
                                </InputAdornment> : null
                    ,
                    endAdornment:
                        props.calc ?
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: '#00000000',
                                            color: "white",
                                        },
                                    }
                                }}
                                title={
                                    <div style={{ ...styles.Block, width: 350, backgroundColor: color.white, border: '1px solid #A0A0A0', borderRadius: props.radius == undefined ? 0 : props.radius }}>
                                        <div style={{ ...styles.Block, width: '96%', paddingTop: 10, paddingBottom: 10 }}>
                                            <p style={{ ...styles.TextNormal }}><span style={{ marginRight: 10 }}><FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faCalculator} /></span>{lang.calculator}</p>
                                            <div style={{ ...styles.Block, marginTop: 5, borderTop: '1px solid ' + color.light_gray }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 5, color: color.darker_gray }}>{lang.textfield_calc_1}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 2, color: color.darker_gray }}>{lang.textfield_calc_2}</p>
                                            </div>
                                        </div>
                                    </div>
                                }>
                                <InputAdornment position="end">
                                    <FontAwesomeIcon style={{ height: 20, color: color.dark_gray }} icon={faCalculator} />
                                </InputAdornment>
                            </Tooltip>
                            :
                            props.percentage ?
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#00000000',
                                                color: "white",
                                            },
                                        }
                                    }}
                                    title={
                                        <div style={{ ...styles.Block, width: 450, backgroundColor: color.white, border: '1px solid #A0A0A0', borderRadius: props.radius == undefined ? 0 : props.radius }}>
                                            <div style={{ ...styles.Block, width: '96%', paddingTop: 10, paddingBottom: 10 }}>
                                                <p style={{ ...styles.TextNormal }}><span style={{ marginRight: 10 }}><FontAwesomeIcon style={{ height: 16, color: color.black }} icon={faInfoCircle} /></span>{lang.text_percentage}</p>
                                                <div style={{ ...styles.Block, marginTop: 5, borderTop: '1px solid ' + color.light_gray }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5, color: color.darker_gray, fontWeight: '600' }}>{lang.text_percentage_text_1}</p>
                                                    <p style={{ ...styles.TextXTiny, textAlign: 'center', marginTop: 2, color: color.darker_gray }}>{lang.text_percentage_text_2}</p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 10, color: color.darker_gray, fontWeight: '600' }}>{lang.text_percentage_text_3}</p>
                                                    <p style={{ ...styles.TextXTiny, textAlign: 'center', marginTop: 2, color: color.darker_gray }}>{lang.text_percentage_text_4}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }>
                                    <InputAdornment position="end">
                                        <FontAwesomeIcon style={{ height: 20, color: color.dark_gray }} icon={faInfoCircle} />
                                    </InputAdornment>
                                </Tooltip>
                                :
                                props.search == true ?
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => Clear()} style={{ width: 26, height: 26 }}>
                                            <FontAwesomeIcon style={{ width: 10, color: color.dark_gray }} icon={faXmark} />
                                        </IconButton>
                                    </InputAdornment>
                                    :
                                    props.end != undefined ?
                                        <InputAdornment position="end" >
                                            <div style={{ ...styles.Block, borderLeft: '1px solid ' + color.medium_gray }}>
                                                <p style={{ ...styles.TextTiny, color: color.medium_gray, marginLeft: 5 }}>{props.end}</p>
                                            </div>
                                        </InputAdornment>
                                        : null
                    ,
                    style: { height: props.height == undefined ? props.rows == undefined ? 36 : undefined : props.height, backgroundColor: props.enabled == false ? '#00000007' : color.white, borderRadius: props.radius == undefined ? global.radius : props.radius, color: color.black },
                }}
                type={props.date == true ? 'date' : props.password == true ? 'password' : props.time == true ? 'time' : props.type != undefined ? props.type : 'text'}
                variant="outlined"
                disabled={props.enabled == false ? true : false}
                error={error != '' ? true : props.required == true ? text == '' ? true : false : false}
                helperText={props.enabled == false ? '' : error != '' ? error : props.required == true ? text == '' ? props.outline == true ? '' : lang.required : '' : ''}
                FormHelperTextProps={{ style: { color: color.dark_red, margin: 0, padding: 0, marginLeft: 12, fontSize: 12 } }}
            />
            {
                props.calc && editing == true && calcResult != '' ?
                    <Popper
                        style={{ zIndex: 30002 }}
                        open={calcOpen}
                        anchorEl={anchorEl}
                        placement={'bottom-start'}
                    >
                        <div style={{ ...styles.Block, marginLeft: 5, marginTop: 2, border: '1px solid #777777', padding: 5, borderRadius: props.radius == undefined ? 2 : props.radius, backgroundColor: color.white }}>
                            <p style={{ ...styles.TextTiny }}>= {calcResult}</p>
                        </div>
                    </Popper>
                    : null
            }
        </div >
    );
}


export const FormError = (props) => {
    /*

            <FormError theme={props.theme} error={error} small />
            -----------------------------------------------------
        small   -> menšia verzia
        margin  -> marginfrom top
            */
    const height = 40;
    let color = global.themes[0];

    return (
        <div style={{ ...styles.Block, width: props.small == true ? undefined : '100%', minHeight: height, backgroundColor: props.backgroundColor == undefined ? color.dark_red : props.backgroundColor, borderRadius: 20, marginTop: props.margin == undefined ? 20 : props.margin }}>
            {props.error != '' ?
                <div style={{ ...styles.BlockRowRaw }}>
                    {props.isSmall ? null :
                        <FontAwesomeIcon style={{ height: 18, color: color.white, marginLeft: 15 }} icon={faWarning} />
                    }
                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextXSmall, color: color.white, marginLeft: 10, marginRight: 15 }}>{props.error}</p>
                </div>
                : null}
        </div>
    );
}

export const GetPages = (count, itemsMax) => {
    // PAGIMATION - výpočet počtu strán
    return Math.ceil(count / itemsMax);
}



export const Avatar = (props) => {

    /*

        <Avatar editable picture image={ } size={ } theme={props.theme} func={AvatarPress.bind()} />

        PARAMS:
        ----------------------------------------------------------------------------------------------------
        editable    -> zobrazí sa ikona ceruzky - button
        picture     -> default image je obrázok, inak person
        size        -> velkost avataru

        RESULT:
        ----------------------------------------------------------------------------------------------------
    const AvatarPress = (value) => {
        if (value == 1) {
            setSelectPhoto(true);
        }
        if (value == 0) {
            setImage('');
        } if (value == 2) {
            setShowColors(true);
        }
    }

    */

    // IMAGE
    const user_default = require('./react/app/user.png');
    const image_default = require('./react/app/default_image.png');

    // AVATAR SIZE
    const size = 100;

    // PARAMS
    let color = global.themes[props.theme];
    let lang = props.lang;
    let { func } = props;

    const [over, setOver] = useState(false);

    useEffect(() => {

        //setImage(props.image == undefined || props.image == false || props.image == '' ? props.picture == undefined ? user_default : image_default : props.image);

        return () => {
        };

    }, [props.image]);

    const Process = (value) => {
        func(value);
    }

    const Delete = () => {
        func(false);
    }

    const MouseOver = (value) => {
        if (props.editable == true) {
            setOver(value);
        }
    }

    return (
        <div style={{ ...styles.Block, width: props.size == undefined ? size : props.size, height: props.size == undefined ? size : props.size, justifyContent: 'center', position: 'relative' }}>
            <div style={{ ...styles.Block }}>
                {props.image == undefined || props.image == false || props.image == '' ?
                    <img src={props.picture == undefined ? user_default : image_default} style={{ width: '100%', height: '100%', maxWidth: props.size == undefined ? size : props.size, maxHeight: props.size == undefined ? size : props.size, objectFit: 'contain', borderRadius: 10 }}></img>
                    :
                    props.image.substring(0, 1) == '#' ?
                        <div style={{ ...styles.Block, width: props.size == undefined ? size : props.size, height: props.size == undefined ? size : props.size, backgroundColor: props.image, borderRadius: props.size == undefined ? size / 2 : props.size / 2 }}></div>
                        :
                        <img src={GetImageLink(props.image)} style={{ width: '100%', height: '100%', maxWidth: props.size == undefined ? size : props.size, maxHeight: props.size == undefined ? size : props.size, objectFit: 'contain', borderRadius: 10 }}></img>
                }

            </div>
            <div style={{ ...styles.Block, width: props.size == undefined ? size : props.size, height: props.size == undefined ? size : props.size, position: 'absolute', cursor: props.editable == true ? 'pointer' : undefined }}
                onMouseOverCapture={() => MouseOver(true)} onMouseLeave={() => MouseOver(false)}
            >
                {over == true && props.editable == true ?
                    <div style={{ ...styles.BlockRowRaw }}>
                        <IconButton onClick={() => Process(1)} style={{ ...styles.ButtonIcon, backgroundColor: '#FFFFFFB0' }}>
                            <FontAwesomeIcon style={{ height: 12 }} icon={faPen} />
                        </IconButton>
                        {props.color == true ?
                            <IconButton onClick={() => Process(2)} style={{ ...styles.ButtonIcon, backgroundColor: '#FFFFFFB0', marginLeft: 6 }}>
                                <FontAwesomeIcon style={{ height: 12 }} icon={faPalette} />
                            </IconButton>
                            : null}

                        {props.image == undefined || props.image == false || props.image == '' ? null :
                            <IconButton onClick={() => Delete()} style={{ ...styles.ButtonIcon, backgroundColor: '#FFFFFFB0', marginLeft: 6 }}>
                                <FontAwesomeIcon style={{ height: 12 }} icon={faTrash} />
                            </IconButton>
                        }
                    </div>
                    : null}
            </div>
        </div >
    );
}


export const PlusMinus = (props) => {
    /*
                PLUS - MINUS HODNOTY

                USAGE:
                <PlusMinus min={1} value={value} item={item} theme={props.theme} lang={props.lang} func={PlusMinusResult.bind(this)} />

                ----------------------------------------------------------------------------------------

                min         -> minimalna hodnota 
                max         -> maximálna hodnota 
                no_wait     -> bez cakacej rutiny
                item        -> array - len prenos udajov
                ----------------------------------------------------------------------------------------

                const PlusMinusResult = (value, item) => {
                    console.log(value)
                }

    */

    const [value, setValue] = useState(props.value);
    const [edit, setEdit] = useState(false);

    let { func } = props;

    const width = 120;
    const buttonSize = 30;
    const height = 36;
    let color = global.themes[props.theme];
    const inputRef = useRef(null);

    const timer = useRef();

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value]);

    const Press = (value) => {
        func(value, props.item);
    }

    const Process = (add) => {
        var old_value = parseInt(value);
        var number = parseInt(parseInt(value) + parseInt(add));
        if (props.min != undefined) {
            if (number < props.min) {
                number = props.min;
            }
        } else {
            if (number < 0) {
                number = 0;
            }
        }
        if (props.max != undefined) {
            if (number > props.max) {
                number = props.max;
            }
        }
        setValue(number);

        // opozdovacia rutina
        if (old_value != number) {
            clearTimeout(timer.current);
            if (props.no_wait == undefined) {
                timer.current = setTimeout(() => {
                    Press(number);
                }, 500);
            } else {
                Press(number);
            }
        }
    }

    const EnterValue = () => {
        var number = parseInt(value);
        if (props.min != undefined) {
            if (number < props.min) {
                number = props.min;
            }
        } else {
            if (number < 0) {
                number = 0;
            }
        }
        if (props.max != undefined) {
            if (number > props.max) {
                number = props.max;
            }
        }
        setValue(number);
        Press(number);

        setEdit(false);
    }

    const StartEdit = () => {
        setEdit(true);
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);

    }

    return (
        edit == false ?
            <div style={{ ...styles.Block, width: width, height: height, backgroundColor: color.white, borderRadius: height / 2, border: color.border }}>
                <div style={{ ...styles.BlockRow, width: width - 8, height: height, backgroundColor: color.white, borderRadius: height / 2 }}>
                    <div style={{ ...styles.Block, width: buttonSize, height: height }}>
                        <IconButton onClick={() => Process(-1)} style={{ width: buttonSize, height: buttonSize, backgroundColor: props.min == undefined ? color.dark : parseInt(value) == props.min ? color.light : color.dark, borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
                            <FontAwesomeIcon style={{ width: 12, color: color.white }} icon={faMinus} />
                        </IconButton>
                    </div>
                    <div onClick={() => StartEdit()} style={{ ...styles.Block, width: width - 8 - (buttonSize * 2), height: height, cursor: 'pointer' }}>
                        <p style={{ ...styles.TextTiny, color: props.min == undefined ? color.black : parseInt(value) == props.min ? color.medium_gray : color.black }}>{value}</p>
                    </div>
                    <div style={{ ...styles.Block, width: buttonSize, height: height }}>
                        <IconButton onClick={() => Process(1)} style={{ width: buttonSize, height: buttonSize, backgroundColor: props.max != undefined ? props.max == parseInt(value) ? color.light : color.dark : color.dark, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
                            <FontAwesomeIcon style={{ width: 12, color: color.white }} icon={faPlus} />
                        </IconButton>
                    </div>
                </div >
            </div>
            :
            <div style={{ ...styles.BlockRow, width: width, height: height, backgroundColor: color.white, borderRadius: height / 2 }}>
                <TextField
                    inputRef={inputRef}
                    value={value}
                    onInput={(e) => setValue(e.target.value)}
                    size="small"
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    style={{ width: '100%' }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            EnterValue();
                        }
                    }}
                    InputProps={{
                        style: { backgroundColor: global.theme_white, borderRadius: global.radius },
                    }}
                    onFocus={event => {
                        event.target.select();
                    }}
                    variant="outlined"
                />
                <IconButton onClick={() => EnterValue()} style={{ ...styles.ButtonIcon, width: 36, height: 36, borderRadius: global.radius, marginLeft: 5, backgroundColor: color.light_green }}>
                    <FontAwesomeIcon style={{ width: 12 }} icon={faCheck} />
                </IconButton>

            </div>
    );
}


export const ShowOK = (props) => {
    /*
            USAGE:
            const [showOK, setShowOK] = useState(false);
            setShowOK(true);


            const ShowOKPress = (value) => {
                setShowOK(false);
            }

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            */

    const [width, setWidth] = useState(window.innerWidth);
    let { func } = props;
    let color = global.themes[props.theme];
    let size = 48;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 750);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', top: -100 }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: size, height: size, backgroundColor: color.darker_green, borderRadius: 40 }}>
                <FontAwesomeIcon style={{ width: size - 15, height: size - 15, color: color.white }} icon={faCheck} />
            </Paper>
        </Backdrop>
    );
}


export const ShowError = (props) => {
    /*
            USAGE:
            const [showError, setShowError] = useState(false);
            setShowError(true);

            const ShowErrorPress = (value) => {
                setShowError(false);
            }

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={ShowErrorPress.bind(this)} />
                : null}

            */

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    let color = global.themes[0];

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 4000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 360, backgroundColor: color.black, borderRadius: 16, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.Block, width: 40, height: 40, borderRadius: 20, backgroundColor: color.dark_red }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: color.white }} icon={props.icon == undefined ? faXmark : props.icon} />
                </div>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...styles.TextSmall, textAlign: 'center', color: color.white, textAlign: 'center', marginTop: 15 }}>{props.text}</p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, color: color.white, textAlign: 'center', marginTop: 8 }}>{props.sub_text}</p>
                        : null}
                </div>
            </Paper>
        </Backdrop >
    );
}



export const FormSpace = (props) => {
    /*

            <FormSpace />
            -----------------------------------------------------
        
        
    */

    return (
        <div style={{ ...styles.Block, minHeight: props.height == undefined ? 20 : props.height }}>
        </div >
    );
}
