import './globals.js';

export const styles = {
    Block: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    BlockRaw: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    BlockCenter: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    BlockRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%'
    },
    BlockRowRaw: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    BlockLeft: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    BlockRight: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    BlockMaxLarge: {
        // BLOK S MAX.PREDNASTAVENOU SIRKOU
        display: 'flex',
        flexDirection: 'column',
        maxWidth: global.screen_max,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    BlockMaxSmall: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    BlockMenu: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: global.menu_width,
    },
    BlockMenuTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: global.menu_width,
        backgroundColor: '#B7CFFF'
    },
    BlockRound: {
        // BOX okrúhly pre faCheck
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '50%',
        width: 40,
        height: 40,
    },
    // ************************************************************************************************
    // BUTTONS
    // ************************************************************************************************
    ButtonThemed: {
        color: '#FFFFFF',
        fontSize: 15,
        width: 160,
        height: 34,
        borderRadius: 17,
        textTransform: 'none',
        fontWeight: '400',
        letterSpacing: 0
    },
    ButtonOutlined: {
        color: '#606060',
        fontSize: 15,
        width: 160,
        height: 34,
        borderRadius: 17,
        textTransform: 'none',
        fontWeight: '400',
        letterSpacing: 0,
        border: '1px solid #606060',
    },
    ButtonThemedSmall: {
        color: '#FFFFFF',
        fontSize: 15,
        width: 160,
        height: 28,
        borderRadius: 14,
        textTransform: 'none',
        fontWeight: '400'
    },
    ButtonStatusBar: {
        color: '#000000',
        fontSize: 13,
        width: 160,
        height: global.status_height,
        borderRadius: 0,
        textTransform: 'none',
        fontWeight: '400',
        letterSpacing: 0,
        borderLeft: '1px solid #808080',
        borderRight: '1px solid #808080',
    },
    ButtonClose: {
        color: '#000000',
        fontSize: 15,
        width: 36,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400',
        backgroundColor: '#FFFFFF'
    },
    ButtonIcon: {
        color: '#000000',
        fontSize: 14,
        width: 36,
        height: 36,
        borderRadius: 18,
        textTransform: 'none',
        fontWeight: '400',
        //backgroundColor: '#FFFFFF',
        border: '1px solid #B0B0B0'
    },
    ButtonUser: {
        color: '#606060',
        fontSize: 14,
        width: 34,
        height: 34,
        borderRadius: 17,
        textTransform: 'none',
        fontWeight: '400',
        border: '1px solid #606060'
    },
    ButtonIconThemed: {
        color: '#000000',
        fontSize: 14,
        width: 30,
        height: 30,
        borderRadius: 15,
        textTransform: 'none',
        fontWeight: '400',
    },
    ButtonLink: {
        color: '#000000',
        fontSize: 14,
        width: 220,
        height: 30,
        textTransform: 'none',
        fontWeight: 'normal',
        letterSpacing: 0,
        textDecoration: 'underline',
        lineHeight: 1,
    },
    // MAIN MENU BUTTON
    ButtonMenu: {
        backgroundColor: '#00000000',
        textTransform: 'none',
        fontWeight: 'normal',
        justifyContent: 'left',
        margin: 0,
        padding: 0,
        paddingLeft: 20,
        width: global.menu_width - 20,
        height: 36,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 18,
        borderBottomRightRadius: 18,
        fontSize: 12,
    },
    ButtonSubMenu: {
        color: '#A0A0A0',
        fontSize: 14,
        width: global.sub_menu_width - 5,
        height: 40,
        minHeight: 40,
        textTransform: 'none',
        fontWeight: 'normal',
        borderRadius: '20px 0px 0px 20px',
        marginLeft: 5,
        marginTop: 2,
        marginBottom: 2,
        justifyContent: 'flex-start',
        paddingLeft: 17,
        textAlign: 'left',
        lineHeight: 1,
        letterSpacing: 0,
        borderRight: '6px solid #0088FF'
    },
    Label: {
        fontSize: global.font_label,
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'left'
    },
    TextXXLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 70,
        color: '#000000',
        textAlign: 'left'
    },
    TextXLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xlarge,
        color: '#000000',
        textAlign: 'left'
    },
    TextLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_large,
        color: '#000000',
        textAlign: 'left'
    },
    TextNormal: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_normal,
        color: '#000000',
        textAlign: 'left'
    },
    TextMenu: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 17,
        color: '#000000',
        textAlign: 'left'
    },
    TextInput: {
        margin: 0,
        marginTop: 0,
        marginBottom: 3,
        fontSize: 14,
        color: global.theme_dark_gray,
        fontWeight: '400',
        textAlign: 'left'
    },
    TextSmall: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_small,
        color: '#000000',
        textAlign: 'left'
    },
    Text: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xsmall,
        color: '#000000',
        textAlign: 'left'
    },
    TextXSmall: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xsmall,
        color: '#000000',
        textAlign: 'left'
    },
    TextTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_tiny,
        color: '#000000',
        textAlign: 'left'        
    },
    TextXTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xtiny,
        color: '#000000',
        textAlign: 'left'
    },
    TextXXTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xxtiny,
        color: '#000000',
        textAlign: 'left'
    },
    TextXXXTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 11,
        color: '#000000',
        textAlign: 'left'
    },
    TextTitle: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xlarge,
        color: '#000000',
        textAlign: 'left'
    },
    TextTinyMobile: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_tiny,
        color: '#000000',
        textAlign: 'left'
    },
    ButtonPin: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        height: 80,
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
        fontSize: global.font_xxlarge,
        color: global.theme_white,
        backgroundColor: global.theme_dark,
        cursor: 'pointer'
    },

    TextPin: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 16,
        height: 16,
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
        fontSize: global.font_xxlarge,
        color: global.theme_white,
        backgroundColor: global.theme_dark,
        textAlign: 'left'
    },

    BorderTop:
    {
        borderTop: '1px solid ' + global.theme_gray
    },
    BorderBottom:
    {
        borderBottom: '1px solid ' + global.theme_gray
    },
    BorderTopBold:
    {
        borderTop: '3px solid ' + global.theme_gray
    },
    BorderBottomBold:
    {
        borderBottom: '3px solid ' + global.theme_gray
    },
    Border:
    {
        border: '1px solid ' + global.theme_gray
    },
    TextDialogLabel: {
        fontWeight: '500',
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 18,
        letterSpacing: 0.2,
        color: '#000000',
    },
    TextDialogSubLabel: {
        fontWeight: '300',
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 14,
        letterSpacing: 0.5,
        color: '#333333',
    },
};

