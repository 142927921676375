/*
*
* =================================================================================================================
* QUIZ

    localhost:3001/quiz/1/LNK-0ZXYbVeG-65a0fed6a414b-g2d9YZck-Vw6nLL2f-Z1111ekY/12345678
    

* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { FormError, FormSpace, Icon, Loading, ShowError, ShowOK, TextInput, Avatar, ContactCompany, PlusMinus } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, FormControlLabel, IconButton, Pagination, Paper, Radio, RadioGroup, Skeleton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faPlus, faInfoCircle, faLayerGroup, faLocationDot, faPlusCircle, faQuestion, faXmark, faAngleLeft, faArrowAltCircleLeft, faArrowAltCircleRight, faPlayCircle, faAngleRight, faAngleUp, faAngleDoubleDown, faAngleDown, faQuestionCircle, faComment, faCheck, faPen, faCommentAlt, faCircle, faFrown, faSmile } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, GetLang, GetLeftPosition, GetPages, IsEmpty, UniqueID } from './functions';
import { GetDatumTime, GetDatumTime2, Today, TodayDateTime } from './functions_date.js';
import { sk } from './language_sk.js';

/*
*
* =================================================================================================================
* SPUSTENIE QVÍZU
*
*   PARAMS:
* -----------------------------------------------------------------------------------------------------------------
*   customer        -> zakazník
*   save            -> ukladanie umoznené
* =================================================================================================================
*
*/
export const QuizPlay = (props) => {

    let params = useParams();
    let radius = props.radius;
    let theme = 0;
    var color = global.themes[theme];

    // IMAGES
    const no_image = require('./react/app/user.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    // OTAZKY
    const [quiz, setQuiz] = useState(false);
    const [questions, setQuestions] = useState(false);
    const [url, setURL] = useState('');
    const [organizationID, setOrganizationID] = useState(0);
    const [language, setLanguage] = useState(params.language > 2 || params.language < 1 ? 1 : params.language);

    const [saved, setSaved] = useState(false);

    const buttonHeight = 70;
    const errorHeight = 40;
    const rowOffset = 20;
    const radioOffset = 10;

    var lang = GetLang(language);
    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            func(2, false); // SKRYT MENU A FOOTER

            if (props.item != false) {
                db_get();
            }

            running = true;
        }

    }, []);


    const db_get = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'quiz', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organization_uid: params.organization_uid,
                    uid: params.quiz_uid,
                })
            })

            const json = await response.json();
            Debug(json);

            if (json.ok > 0) {
                if (json.quiz == false) {
                    setDisabled(true);
                    Debug('Disabled');
                } else {
                    setDisabled(false);
                    setURL(json.url);
                    setQuiz(json.quiz);
                    setOrganizationID(json.organization_id);
                    setLanguage(json.quiz.country_id);

                    var items = json.questions;

                    // DEFAULT PREDVYPLNENE HODNOTY
                    items.forEach(item => {
                        if (item.typ == 1) {
                            item.result = -1;
                        }
                        if (item.typ == 2) {
                            item.result = 1;
                        }
                        if (item.typ == 3) {
                            item.result = -1;
                        }
                        if (item.typ == 4) {
                            item.result = '';
                        }
                        if (item.typ == 5) {
                            item.result = Today();
                        }
                        if (item.typ == 6) {
                            item.result = '09:00:00';
                        }
                    });
                    setQuestions(items);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_result = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'quiz_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organization_uid: params.organization_uid,
                    uid: params.uid,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                setSaved(true);
            } else {
                setError(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Save = () => {
        var result = [];
        var err = false;
        setError('');

        Debug('question');
        questions.forEach(question => {

            if (question.typ == 3 && question.multiselect == true) {
                if (question.answers != false) {
                    var array = [];
                    question.answers.forEach(answer => {
                        array.push({ id: answer.id, value: answer.result == undefined ? 0 : answer.result, typ: question.typ, multi: question.multiselect });
                    });
                    question.result = array;
                }
            }
            let data = {
                question_id: question.id,
                value: question.result,
                typ: question.typ,
                multi: question.multiselect
            }

            question.error = false;
            if (question.result == undefined || question.result == -1) {
                question.error = true;
                err = true;
            }

            result.push(data);
        });

        if (err == true) {
            setError(lang.quiz_answer_required);
        } else {
            let data = {
                id: 0,
                organization_id: organizationID,
                quiz_id: quiz.id,
                user_id: 0,
                customer_id: 0,
                results: JSON.stringify(result),
                score: 0
            }

            Debug(data);
            db_result(data);
        }

    }

    const PlusMinusResult = (value, item) => {
        var tmp = questions.find(x => x.id == item.id);
        if (tmp != undefined) {
            tmp.result = value;
        }
    }

    return (
        <div style={{ ...styles.Block, maxWidth: 800 }}>
            {disabled == true ?
                <div style={{ ...styles.Block, paddingTop: 100 }}>
                    <FontAwesomeIcon style={{ height: 30, color: color.dark_red }} icon={faFrown} />
                    <p style={{ ...styles.TextLarge, marginTop: 20 }}>{lang.quiz_answer_404}</p>
                </div>
                :
                saved == true ?
                    <div style={{ ...styles.Block, paddingTop: 100 }}>
                        <FontAwesomeIcon style={{ height: 30, color: color.dark_green }} icon={faSmile} />
                        <p style={{ ...styles.TextLarge, marginTop: 20 }}>{lang.quiz_saved}</p>
                    </div>
                    :
                    <div style={{ ...styles.Block }}>
                        {/* BODY */}
                        {quiz != false ?
                            <div style={{ ...styles.BlockCenter, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, backgroundColor: color.light_gray }}>
                                        {quiz.image != '' ?
                                            <div style={{ ...styles.Block, marginBottom: 20 }}>
                                                <Avatar editable={false} picture image={url + '/' + quiz.image} size={120} theme={theme} func={null} />
                                            </div>
                                            :
                                            <div style={{ ...styles.Block, marginBottom: 20 }}>
                                                <img src={no_image} style={{ width: '100%', height: '100%', maxWidth: 100, maxHeight: 100, objectFit: 'contain' }}></img>
                                            </div>

                                        }
                                        <p style={{ ...styles.TextSmall, color: color.black, textAlign: 'center' }}><b>{quiz.name}</b></p>
                                        {quiz.text != '' ?
                                            <div style={{ ...styles.Block, width: '96%' }}>
                                                <p style={{ ...styles.TextXSmall, color: color.black, textAlign: 'center', marginTop: 10 }}>{quiz.text}</p>
                                            </div>
                                            : null}
                                    </div>

                                    <div style={{ ...styles.Block, width: '96%' }}>
                                        {questions != false ? questions.map((item, index) => (
                                            <div key={item.id} style={{ ...styles.BlockRow, marginTop: rowOffset, marginBottom: rowOffset, paddingBottom: rowOffset, borderBottom: color.border }}>
                                                <div style={{ ...styles.BlockLeft, width: 30 }}>
                                                    <p style={{ ...styles.TextXSmall, color: color.black }}>{index + 1}.</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextXSmall }}><b>{item.name}</b></p>
                                                    {item.error == true ?
                                                        <Chip label={lang.quiz_answer_required} variant='outlined' size={'small'} style={{ borderColor: color.error, color: color.error }}></Chip>
                                                        : null}
                                                    {/*
                                        <p style={{ ...styles.TextTiny, color: color.dark_gray, marginTop: 10 }}>{lang.quiz_answer_user}</p>
                                        */}
                                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                        {item.typ == 1 ?
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <RadioGroup
                                                                    value={item.result}
                                                                    onChange={event => { item.result = (event.target.value); setRedraw(!redraw) }}
                                                                    style={{ flexDirection: 'column' }}
                                                                >
                                                                    <FormControlLabel value={1} control={<Radio style={{ padding: 0, paddingLeft: 10, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{item.text_yes}</p>} />
                                                                    <FormControlLabel value={0} style={{ marginTop: radioOffset }} control={<Radio style={{ padding: 0, paddingLeft: 10, paddingRight: 2 }} />} label={<p style={{ ...styles.TextTiny }}>{item.text_no}</p>} />
                                                                </RadioGroup>
                                                            </div>
                                                            : null}

                                                        {item.typ == 2 ?
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <PlusMinus value={item.result} item={item} theme={theme} lang={props.lang} func={PlusMinusResult.bind(this)} />
                                                            </div>
                                                            : null}

                                                        {item.typ == 3 && item.answers != false ?
                                                            item.multiselect == true ?
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    {item.answers.map((answer, i) => (
                                                                        <FormControlLabel key={i} label={<p style={{ ...styles.TextXSmall }}>{answer.name}</p>}
                                                                            control={<Checkbox checked={answer.result == 1 ? true : false} size={'small'} onChange={event => { answer.result = (event.target.checked == true ? 1 : 0); setRedraw(!redraw); Debug(event.target.checked) }} />}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                :
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <RadioGroup
                                                                        value={item.result}
                                                                        onChange={event => { item.result = (event.target.value); setRedraw(!redraw) }}
                                                                        style={{ flexDirection: 'column' }}
                                                                    >
                                                                        {item.answers.map((answer, i) => (
                                                                            <FormControlLabel key={i} style={{ marginTop: radioOffset }} value={answer.id} control={<Radio style={{ padding: 0, paddingLeft: 10, paddingRight: 2 }} />} label={<p style={{ ...styles.TextXSmall }}>{answer.name}</p>} />
                                                                        ))}
                                                                    </RadioGroup>
                                                                </div>
                                                            : null}

                                                        {item.typ == 4 ?
                                                            <div style={{ ...styles.Block }}>
                                                                <TextInput redraw theme={theme} enabled={true} value={item.result} lang={lang} label={''} func={(txt) => item.result = txt} />
                                                            </div>
                                                            : null}

                                                        {item.typ == 5 ?
                                                            <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                                <TextInput date redraw theme={theme} enabled={true} lang={lang} value={item.result} label={''} width={180} func={(txt) => item.result = txt} />
                                                            </div>
                                                            : null}

                                                        {item.typ == 6 ?
                                                            <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                                <TextInput time redraw theme={theme} enabled={true} lang={lang} value={item.result} label={''} width={180} func={(txt) => item.result = txt} />
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>

                                </div>
                                <div style={{ ...styles.Block, paddingBottom: 50 }}>
                                    <div style={{ ...styles.Block, paddingBottom: 20 }}>
                                        <FormError small margin={0} error={error} theme={theme} />
                                    </div>
                                    <div style={{ ...styles.Block }}>
                                        {questions != false ?
                                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                            : null}
                                    </div>
                                </div>
                            </div >
                            : null}

                        {showOK == true ?
                            <ShowOK theme={theme} func={() => func(true)} />
                            : null}

                        {showError == true ?
                            <ShowError theme={theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                            : null}

                        {isBusy == true ?
                            <Loading offset={props.offset}></Loading>
                            : null}

                    </div>
            }
        </div>
    );
}
