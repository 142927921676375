
// **********************************************************************
// CZ - LANGUAGE
// **********************************************************************
export const cz = {
    language: 'SK',
    title: 'Vision CRM',
    title1: 'Vision',
    title2: 'CRM',

    // ================================================ ================================================== ==========
    // KONTAKTNÍ INFORMACE
    // ================================================ ================================================== ==========
    company_name: 'Obchodní název',
    company_street: 'Ulice',
    company_town: 'Ulice',
    company_psc: 'PSČ',
    jméno: 'Jméno',
    surname: 'Příjmení',
    name_surname: 'Jméno a příjmení',
    street: 'Ulice',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Město',
    email: 'E-mail',
    email_error: 'Nesprávný formát e-mailové adresy',
    mobil: 'Mobil',
    phone: 'Tel.číslo',
    state: 'Stát',
    logo: 'Logo',
    ico: 'IČ',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    DPH: 'DPH',
    call_us: 'Volejte',
    contact_form: 'Kontaktní formulář',
    contact_form_text: 'Vyplňte náš jednoduchý kontaktní formulář a my se Vám brzy ozveme.',
    message_question: 'Chcete se na něco zeptat? Napište nám.',
    message_text: 'Text zprávy',
    message_email: 'Vaše emailová adresa',
    message_mobil: 'Vaše telefonní číslo',
    message_send_ok: 'Zpráva byla úspěšně odeslána',
    message_send_err: 'Pro odesílání nastala chyba. Zkuste požadavek opakovat.',
    slovakia: 'Slovensko',

    // ================================================ ================================================== ==========
    // OTHERS
    // ================================================ ================================================== ==========
    continue: 'Pokračovat',
    back: 'Zpět',
    close: 'Zavřít',
    required: 'Údaje označené červeně jsou povinné',
    number: 'Číslo',
    active: 'Aktivní',
    label: 'Název',
    db_error: 'Při zápisu nastala chyba. Zkuste požadavek opakovat',
    new: 'Nový',
    add: 'Přidat',
    ok: 'OK',
    cancel: 'Storno',
    save: 'Uložit',
    save_changes: 'Uložit změny',
    edit: 'Upravit',
    search: 'Hledat',
    send: 'Odeslat',
    send_request: 'Odeslat požadavek',
    saving: '...',
    subscribe: 'Odebírat',
    error: 'Chyba',
    goto_app: 'Přejít do aplikace',
    demo: 'Vyzkoušet zdarma',
    captcha_error: 'Chybí potvrzení - nejsem robot',
    send_error: 'Při odesílání požadavku nastala chyba. Zkuste požadavek opakovat',
    back_to_home: 'Zpět na hlavní stránku',
    order: 'Objednávka',
    order_text: 'Zakoupení licence',
    home: 'Domů',
    contact: 'Kontakt',
    menu: 'Menu',
    required_data: 'Údaje označené * jsou povinné',
    watch: 'Podívat',

    // ================================================ ================================================== ==========
    // GDPR + COOKIES + VOP
    // ================================================ ================================================== ==========
    gdpr: 'Ochrana osobních údajů',
    cookies_rules: 'Zásady používání souborů cookies',
    sell_docs: 'Všeobecné obchodní podmínky',

    // ================================================ ================================================== ==========
    // FOOTER
    // ================================================ ================================================== ==========
    copyright: 'Copyright',

    // ============================================================================================================
    // DOWNLOADS
    // ============================================================================================================
    download: 'Stáhnout aplikaci',
    download_title: 'Aplikace Vision CRM',
    download_text: 'určena pro Apple iPad',

    // ============================================================================================================
    // QUIZ
    // ============================================================================================================
    quiz_answer_required: 'Chybějící odpovědi',
    quiz: 'Anketa',
    quiz_disabled: 'Link na anketu byl vymazán',
    quiz_404: 'Neexistující link',
    quiz_saved: 'Děkujeme, anketa byla úspěšně odeslána.',

}

