/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { sk } from './language_sk.js';
import './items.css';
import { cz } from './language_cz.js';

/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/

export const Debug = (data, color, typ) => {
    /*
        USAGE:
        ---------------------------------------
        Debug('text');
        Debug('text', 'gray', 'info');
        Debug('text', 'yellow', 'debug');
        Debug('text', 'red', 'warn');

    */

    if (global.testing == true) {
        if (color == undefined && typ == undefined) {
            console.log(data);
        } else {
            if (typ == 'warn') {
                console.warn('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'info') {
                console.info('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'debug') {
                console.debug('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else {
                console.log('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            }
        }
    }
}

export const SaveLanguage = (lang) => {
    localStorage.setItem('visioncrm-stonner-language', lang);
}


export const LoadLanguage = () => {
    var lang = localStorage.getItem('visioncrm-stonner-language');
    if (lang != null) {
        return lang;
    } else {
        return 0;
    }
}

export const GetLang = (language) => {
    var result = sk;
    if (language == 1) {
        result = sk;
    }
    if (language == 2) {
        result = cz;
    }
    return result;
}

export const SaveTheme = (lang) => {
    localStorage.setItem('visioncrm-theme', lang);
}


export const LoadTheme = () => {
    var lang = localStorage.getItem('visioncrm-theme');
    if (lang != null) {
        return lang;
    } else {
        return 0;
    }
}

export function ValidateEmail(mail) {
    // kontrola správnosti e-mailovej adresy
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (true)
    }
    return (false)
};
