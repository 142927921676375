import React, { useEffect, useState, useRef, useCallback } from 'react';
import './App.css';
import './items.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Main } from './main';
import { MenuTop, Menu, Footer } from './items';
import { Debug, GetLang, LoadLanguage, LoadTheme, SaveLanguage } from './functions';
import { styles } from './styles.js';
import { useMediaQuery } from '@mui/material';
import { Downloads } from './downloads.js';
import { Contact } from './contact.js';
import { QuizPlay } from './quiz.js';

function App() {
  const navigate = useNavigate();

  const loadingScreen = document.getElementById('loading-screen');

  const [theme, setTheme] = useState(LoadTheme());
  const [language, setLanguage] = useState(LoadLanguage());
  const [menuID, setMenuID] = useState(0);
  const [company, setCompany] = useState(false);
  const [showmenus, setShowMenus] = useState(true);

  // SCREEN SIZES
  const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
  const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
  const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

  // SCREEN SETTINGS
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  var color = global.themes[theme];
  var lang = GetLang(language);

  var running = false;

  useEffect(() => {
    
    if (running == false) {
      //db_get();

      running = true;
    }

    if (loadingScreen) {
      loadingScreen.style.display = 'none';
    }

    function handleResize() {
      setWidth(window.innerWidth);
      //setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* *******************************************************************************
      NAČÍTANIE UDAJOV O SPOLOCNOSTI
  ******************************************************************************* */
  const db_get = async () => {

    try {
      const response = await fetch(
        global.db_url + 'company', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: 1
        })
      })

      const json = await response.json();
      Debug(json);
      if (json.ok > 0) {
        setCompany(json.company);
      }

    } catch (error) {
      console.log(error);
    }
  }


  const MenuTopPress = (typ, value) => {
    if (typ == 0) {
      // ZMENA JAZYKA
      setLanguage(value);
      SaveLanguage(value);
    }
    if (typ == 1) {
      // FAREBNEJ TEMY
      setTheme(value);
    }

  }

  const MenuPress = (typ, value) => {
    Debug(typ);
    if (typ == 1) {
      // MENU PRESS
      ChangeLink(value);
    }
    if (typ == 2) {
      // ZMENA JAZYKA
      setLanguage(value);
      SaveLanguage(value);
    }
    if (typ == 3) {
      // FAREBNEJ TEMY
      setTheme(value);
    }
  }

  const FooterPress = (typ, id) => {
    // BOL STLAČENÝ LINK      
    if (typ == 0) {
      ChangeLink(id);
    }
  }

  const ChangeLink = (id) => {
    if (id == 0) {
      // HOME
      navigate('/');
    }

  }

  const PageResult = (typ, id) => {
    if (typ == 0) {
      // KAZDA STRANKA PO NAČITANÍ VRATI SVOJ INDEX -> PREPNUTIE MENU
      // 99 => NONE
      setMenuID(id);
    }
    if (typ == 1) {
      // Stlačený link
      ChangeLink(id);
    }
    if (typ == 2) {
      // HIDE MENU / FOOTER ...
      setShowMenus(id);
    }
  }

  return (
    <div className="App" style={{ ...styles.BlockCenter, backgroundColor: color.white, WebkitUserSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', userSelect: 'none' }}>

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />

      <Helmet>
        <title>{global.title}</title>
        <meta name="description" content="Vision CRM" />
      </Helmet>

      <div style={{ ...styles.BlockCenter }}>

        {showmenus == true ? isSmall ? null :
          <MenuTop company={company} isSmall={isSmall} theme={theme} lang={lang} language={language} func={MenuTopPress.bind(this)} />
          : null}

        {showmenus == true ?
          <Menu id={menuID} company={company} isSmall={isSmall} theme={theme} lang={lang} language={language} func={MenuPress.bind(this)} />
          : null}

        <Routes>
          <Route path="/" element={<Main company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/downloads" element={<Downloads company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/downloads/:email" element={<Downloads company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/contact" element={<Contact company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
          <Route path="/quiz/:language/:organization_uid/:quiz_uid" element={<QuizPlay func={PageResult.bind(this)} />} />
          <Route path="*" element={<Main company={company} theme={theme} lang={lang} language={language} func={PageResult.bind(this)} />} />
        </Routes>

        {showmenus == true ?
          <Footer company={company} theme={theme} lang={lang} language={language} func={FooterPress.bind(this)} />
          : null}

      </div >
    </div >
  );
}

export default App;
