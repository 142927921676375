/*
*
* =================================================================================================================
* DÁTUMOVE FUNKCIE
* =================================================================================================================
*
*/

export const Today = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const TodayDateTime = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    var hour = String(today.getHours()).padStart(2, '0');
    var min = String(today.getMinutes()).padStart(2, '0');
    var sec = String(today.getSeconds()).padStart(2, '0');
    today = y + '-' + m + '-' + d + ' ' + hour + ':' + min + ':' + sec;

    return today;
}

export const GetTodayDatum = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = d + '.' + m + '.' + y;

    return today;
}

export const GetTodayYearMonth = () => {
    var today = new Date();

    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m;

    return today;
}

export const GetTodayYear = () => {
    var today = new Date();

    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y;

    return today;
}