//import img_background from './react/app/background.jpg';

global.themes = [
    {
        id: 0,
        test: '#0000FF',
        //background_image: img_background,
        back: '#F0F0F0',
        back_blue: '#F7F8FD',

        top_bar: 'linear-gradient(to right,#FFFFFF,#D8D8D8)', // farba TOP MENU

        // TOP MENU
        top_menu_color: '#101531',
        button_palette: 'linear-gradient(to right,#FF0000,#0000FF)',

        // MAIN MENU
        menu_color: 'linear-gradient(to right,#4076B5,#426D9F)', // farba TOP MENU
        menu_background: '#101531',

        // FOOTER
        footer_background: '#0D0F2C',

        // THEMED COLORS
        lighteen: '#D7E8FF',
        lighter: '#BBD6FD',
        light: '#95B2DC',
        medium: '#5F7AA1',
        dark: '#3D5273',
        darker: '#29364A',

        lighteen_blue: '#E2ECFF',
        lighter_blue: '#92BBEC',
        light_blue: '#2A8EFF',
        blue: '#205CCA',
        dark_blue: '#003BA4',
        darker_blue: '#041A47',

        lighteen_red: '#FFE4E2',
        lighter_red: '#F99891',
        light_red: '#F77268',
        red: '#F44336',
        dark_red: '#C3170B',
        darker_red: '#801000',

        lighteen_green: '#E2FFE3',
        light_green: '#A6FA9D',
        green: '#46F436',
        dark_green: '#18BB18',
        darker_green: '#15A115',
        darkest_green: '#107010',

        yellow: '#FFDD7A',
        badge: '#A12E28',
        placeholder: '#cccccc',

        grayer: '#f7f7f7',
        gray: '#eeeeee',
        light_gray: '#dedede',
        xxgray: '#CCCCCC',
        middle_gray: '#A0A0A0',
        medium_gray: '#888888',
        dark_gray: '#666666',
        darker_gray: '#252525',

        lighteen_violet: '#E9E2FF',
        light_violet: '#FFAAFF',
        violet: '#BB00BB',
        orange: 'orange',
        dark_orange: '#D47300',
        light_cyan: '#80A0FF',
        cyan: 'cyan',
        dark_cyan: '#1080B0',
        brown: 'brown',

        white: '#FFFFFF',
        black: '#000000',

        // BUTTONS
        button_light: '#92C2E7',
        button_medium: '#226AA3',
        button_dark: '#103450',

        // NEAKTIVNA POLOZKA - farba pozadia
        disabled: '#D0D0D0',
        disabled_border: '4px solid #FF0000',

        // default border
        border: '1px solid #dedede',

        text_dark: '#333C52',

        // BUTTONS
        button_light: '#92C2E7',
        button_medium: '#226AA3',
        button_dark: '#103450',

        button_ok: '#103450',
        button_cancel: '#226AA3',
        button_add: '#0080E6',
        button_gray: '#DDDDDD',
        button_dark_gray: '#707070',
        button_action: '#92C2E7',
        button_action_in_bar: '#527AA7',
        button_delete: '#AA0000',
        button_finalize: '#A00000',
        button_ai: '#0044AA',
        button_selected: '#CFE0FF',
        button_selected_dark: '#80A0C0',
    },
    {
        id: 1,
        test: '#FF0000',
    }
]

