/*
*
* =================================================================================================================
* DATABASE NAME:
* =================================================================================================================
*
*/

global.testing = process.env.REACT_APP_MARVISTONER_TEST == 0 ? false : true;


global.max_screen = 1500;
global.max_banner_screen = 1500; // MAXIMALNA SIRKA BANNERA
global.max_width = 1050;
global.max_width_padding = '90%'; // PADDING Z MAXIMALNEJ SIRKY
global.items_max = 10;

global.list_padding = 150;

// DATABAZA
global.web = global.testing == false ? 'https://vizzie.app' : 'https://vizzie.app';
global.db_url = global.web + '/app_remote/';

global.title = 'Vizzie';

// THEMES
global.themes_enabled = false;

// ******************************************************************************************
// PISMO
// ******************************************************************************************
global.font_xxtiny = 12;
global.font_xtiny = 13;
global.font_tiny = 14;
global.font_xsmall = 15;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_table = 14;


// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 850; //650
global.device_medium = 1200;
global.screen_max = 950;
global.tablet_width = 1200;

// **********************************************************************
// MENU / FOOTER SIZES
// **********************************************************************
global.menu_top_size = 46;
global.menu_size_expanded = 80;
global.menu_size_stacked = 50;
global.banner_size = 200;
global.footer_size = 100;

// **********************************************************************
// COMPANY
// **********************************************************************
global.company = {
    company: 'Datadream s.r.o.',
    name_surname: 'Vladimír Gejdoš',
    street: 'Viničná 52/16',
    town: 'Machulince',
    psc: '951 93',
    email: 'info@datadream.sk',
    mobil: '+421 905 668 294',
    ico: '54084253',
    dic: '2121566524'
};


// **********************************************************************
// NAVIGATE INDEX - NEVYUZITE - LEN PRE INFORMACIU O INDEXOCH
// **********************************************************************
global.navigate = [
    { id: 0, note: 'home' },

]

// **********************************************************************
// GALERIA - OBKLADY
// **********************************************************************

global.navigate = [
    { id: 0, image: '' }
]

// **********************************************************************
// IMAGE TYPES
// **********************************************************************
global.gallery = {
    obklady: 0,
    dlazby: 1,
    muriva: 2,
    ostatne: 3
}